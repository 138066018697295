import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './kea-section.scss';
import TypewriterEffect from "../animated-text/typewrittereffect";

function KeaSectionComponent() {

    return (
        <>
            <section>
                <div className="row kea-section">
                    <div className="col-12 separator">
                        <h1 className="kea-section-title">KeaLab</h1>
                    </div>
                    <TypewriterEffect text="1 Software company specialising in creating innovative and user-centred applications" />
                </div>
            </section>
        </>
    )
}

export default KeaSectionComponent;