import React, { useEffect, useState } from 'react';
import './typewritereffectsignature.scss';

const TypewriterEffectSignature = ({ text }) => {
    const [parts, setParts] = useState([]);

    useEffect(() => {
        if (parts.length < text.length) {
            const timeoutId = setTimeout(() => {
                setParts(parts => [
                    ...parts,
                    <span key={parts.length} style={{ color: getColorForIndex(parts.length) }}>
                    {text[parts.length]}
                  </span>
                ]);
            }, 100); // Velocidad de escritura ajustable

            return () => clearTimeout(timeoutId);
        }
    }, [parts, text]);

    const getColorForIndex = (index) => {
        if (index < 7) return '#3E96E6';
        return '#C1FF72';
    };

    return (
        <div className="typewriter">
            {parts}
            <span className="cursor">|</span>
        </div>
    );
};

export default TypewriterEffectSignature;
