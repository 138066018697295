import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './projects.scss';
import wordWavesLogo from '../assets/images/wordwaves-logo.svg';
import { Link } from 'react-router-dom';
function ProjectsComponent() {

    return (
        <>
            <section id="projects">
                <div className="row kea-section">
                    <div className="col-12 separator">
                        <h1 className="project-title">Projects</h1>
                    </div>
                </div>

                <div className="card-container">
                    <div className="logo">
                        <img src={ wordWavesLogo } alt="wordwaves-logo"/>
                    </div>
                    <div className="second-section">
                    <div className="text">
                        <div className="card-title">
                            WordWave · App
                        </div>
                        <div className="card-subtitle">
                            Dive into daily inspiration with a wave of quotes.
                        </div>
                    </div>
                    <div className="card-action">
                        <Link to="/projects">
                            <button type="button" className="btn btn-outline-secondary">Download</button>
                        </Link>

                    </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProjectsComponent;