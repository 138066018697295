import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import logo from '../assets/images/logo-brand.svg';
import './simple-header.scss';
import { Link } from 'react-router-dom';


function SimpleHeaderComponent() {
    return (
        <>
            <nav className="navbar navbar-expand-lg">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} alt="logo-brand"/>
                    </Link>


                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div></div>
                        <div className="navbar-nav">

                        </div>


                    </div>

                </div>
            </nav>
        </>
    );
}

export default SimpleHeaderComponent;