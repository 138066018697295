import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './footer.scss';
import linkedin from "../assets/images/linkedin.svg";
import instagram from "../assets/images/instagram.svg";

function FooterComponent() {

    const handleClick = (url) => {
        window.open(url, "_blank");
    }

    return (
        <>
            <footer className="footer w-100">
                <div className="footer__container">
                    <div className="footer__social">
                        <img src={instagram} alt="instagram" onClick={() => handleClick('https://www.instagram.com/kealabtech/')} />
                        <div className="footer__separator"></div>
                        <img src={linkedin} alt="linkedin" onClick={() => handleClick('https://www.linkedin.com/company/kea-lab/')} />
                    </div>
                    <div className="footer__brand">
                        kealab.tech
                    </div>
                </div>
            </footer>
        </>
    )
}

export default FooterComponent;