
import PresentationComponent from "../../presentation/presentation";
import KeaSectionComponent from "../../kea-section/kea-section";
import ProjectsComponent from "../../projects/projects";
import AboutUsComponent from "../../about-us/about-us";
import ContactComponent from "../../contact/contact";
import HeaderComponent from "../../header/header";

function HomePageComponent() {
    return (
        <>
            <HeaderComponent/>
            <div className="container container-body">
                <PresentationComponent/>
                <KeaSectionComponent/>
                <ProjectsComponent/>
                <AboutUsComponent/>
                <ContactComponent/>
            </div>
        </>
    );
}

export default HomePageComponent;
