import ProjectsListComponent from "../../projects-list/projects-list";
import SimpleHeaderComponent from "../../simple-header/simple-header";

function HomePageComponent() {
    return (
        <>
            <SimpleHeaderComponent/>
            <div className="container container-body">
                <ProjectsListComponent/>
            </div>
        </>
    );
}

export default HomePageComponent;
