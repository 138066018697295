import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './about-us.scss';
import img1 from '../assets/images/about-us-img-1.png';
import img2 from '../assets/images/about-us-img-2.png';
import linkedin from '../assets/images/linkedin.svg';
import ReactCardFlip from 'react-card-flip';
function AboutUsComponent() {
    const [isFlippedCard1, setIsFlippedCard1] = useState(false);
    const [isFlippedCard2, setIsFlippedCard2] = useState(false);
    const handleClick = (card) => {
        if (card === 1) {
            setIsFlippedCard1(!isFlippedCard1);
        } else if (card === 2) {
            setIsFlippedCard2(!isFlippedCard2);
        }
    };

    const mouseLeave = (card) => {
        if (card === 1) {
            setIsFlippedCard1(false);
        } else if (card === 2) {
            setIsFlippedCard2(false);
        }
    }

    const handleClickLinkedin = (url) => {
        window.open(url, "_blank");
    }

    return (
        <>
            <section className="about-us" id="team">
                <div className="row kea-section">
                    <div className="col-12 separator">
                        <h1 className="about-us__title">About Us</h1>
                    </div>
                </div>
                <div className="about-us__card">
                    <div className="about-us__first-section">
                        <p className="about-us__text about-us__text--color-1">Team</p>
                        <p className="about-us__text about-us__text--color-2">Meet the Duo Behind the Vision</p>
                    </div>
                    <div className="about-us__second-section d-flex">
                        <ReactCardFlip containerClassName="about-us__img-left" isFlipped={isFlippedCard1}
                                       flipDirection="horizontal">
                            <div className="about-us__img-container" onClick={() => handleClick(1)}>
                                <img className="about-us__img about-us__img" src={img1} alt="about-us-img-1"/>
                            </div>
                            <div className="about-us__img-container" onClick={() => handleClick(1)} onMouseLeave={() => mouseLeave(1)}>
                                <div className="about-us__img-container about-us__img about-us__img-2">
                                    <div className="about-us__profile-text">
                                        <div className="about-us__profile--white">
                                            Marianna Niubó
                                        </div>
                                        <div className="about-us__profile--profession about-us__profile--green">
                                            ux · ui designer
                                        </div>
                                        <div className="about-us__profile--linkedin"
                                             onClick={() => handleClickLinkedin('https://www.linkedin.com/in/marianna-niubo/')}>
                                            <img src={linkedin} alt="linkedin"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ReactCardFlip>

                        <ReactCardFlip isFlipped={isFlippedCard2} flipDirection="horizontal">
                            <div className="about-us__img-container" onClick={() => handleClick(2)} >
                                <img className="about-us__img about-us__img-right" src={img2} alt="about-us-img-2"/>
                            </div>
                            <div className="about-us__img-container" onClick={() => handleClick(2)} onMouseLeave={() => mouseLeave(2)}>
                                <div className="about-us__img-container about-us__img about-us__img-1">
                                    <div className="about-us__profile-text">
                                        <div className="about-us__profile--white">
                                            Facundo Abarca
                                        </div>
                                        <div className="about-us__profile--profession about-us__profile--green">
                                            Frontend Developer
                                        </div>
                                        <div className="about-us__profile--linkedin" onClick={() => handleClickLinkedin('https://www.linkedin.com/in/abarcafacundo/?locale=en_US')}>
                                            <img src={linkedin} alt="linkedin"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ReactCardFlip>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUsComponent;

