import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './presentation.scss';

function PresentationComponent() {
    return (
        <>
            <section id="company">
                <div className="row presentation">
                    <div className="col-12">
                        <h1 className="title">Creativity, Innovation, Quality</h1>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PresentationComponent;