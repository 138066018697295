import './App.scss';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import FooterComponent from "./footer/footer";
import HomePageComponent from "./pages/home-page/home-page";
import ProjectsPage from "./pages/projects-page/projects-page";

function App() {
    return (
        <Router>
            <div className="App">
                    <Routes>
                        <Route path="/" element={<HomePageComponent />} />
                        <Route path="/projects" element={<ProjectsPage />} />
                    </Routes>
                <FooterComponent/>
            </div>

        </Router>
    );
}

export default App;
