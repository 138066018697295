import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './projects-list.scss';
import wordWavesLogo from '../assets/images/wordwaves-logo.svg';
import back from '../assets/images/arrow-back.svg';
import {Link} from "react-router-dom";
function ProjectsListComponent() {
    const handleClick = (opt) => {
        if (opt === 1) {
            window.open("https://apps.apple.com/es/app/wordwaves/id6478419740", "_blank");
        } else if (opt === 2) {
           window.open("https://play.google.com/store/apps/details?id=com.fabarca.wordwaves", "_blank");
        }
    };
    return (
        <>
            <section id="projects" className="projects-list">
                <div className="row kea-section ">
                    <div className="col-12 separator">
                        <div className="projects-list__title-container">
                            <div>
                                <Link to={"/"} className="projects-list__back">
                                    <span className="projects-list__back-text"> Back </span>
                                    <img className="projects-list__back-arrow" src={ back } alt="back"/>
                                </Link>
                            </div>
                            <div>
                                <h1 className="projects-list__title">Projects</h1>
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>

                <div className="card-container">
                    <div className="logo">
                        <img src={ wordWavesLogo } alt="wordwaves-logo"/>
                    </div>
                    <div className="second-section">
                    <div className="text">
                        <div className="card-title">
                            WordWave · App
                        </div>
                        <div className="card-subtitle">
                            Dive into daily inspiration with a wave of quotes.
                        </div>
                    </div>
                    <div className="card-action-2">
                        <button type="button" className="btn btn-outline-secondary btn-project btn-top" onClick={() => handleClick(1)}>Download for iOS</button>
                        <button type="button" className="btn btn-outline-secondary btn-project" onClick={() => handleClick(2)}>Download for Android</button>
                    </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProjectsListComponent;