import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import logo from '../assets/images/logo-brand.svg';
import toggleIcon from '../assets/images/toggle-icon-mobile.svg';
import './header.scss';
import { Link } from 'react-scroll';


function HeaderComponent() {
    return (
        <>
            <nav className="navbar navbar-expand-lg">
                <div className="container">
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasExample"
                            aria-controls="offcanvasExample">
                        <img src={toggleIcon} alt="logo-brand"/>
                    </button>
                    <img src={logo} alt="logo-brand"/>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div></div>
                        <div className="navbar-nav">
                            <Link className="nav-link" to="company" spy={true} smooth={true} offset={-50} duration={0}>
                                Company
                            </Link>
                            <Link className="nav-link" to="projects" spy={true} smooth={true} offset={0} duration={0}>
                                Projects
                            </Link>
                            <Link className="nav-link" to="team" spy={true} smooth={true} offset={0} duration={0}>
                                Team
                            </Link>
                        </div>
                        <Link to="contact" spy={true} smooth={true} offset={0} duration={0}>
                            <button type="button" className="btn btn-primary" >Contact</button>
                        </Link>

                    </div>

                </div>
            </nav>
            <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample"
                 aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header">
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                            aria-label="Close">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.46584 7.99995L15.6959 1.76968C16.1014 1.36443 16.1014 0.709193 15.6959 0.30394C15.2907 -0.101313 14.6354 -0.101313 14.2302 0.30394L7.99991 6.53421L1.76983 0.30394C1.36438 -0.101313 0.709335 -0.101313 0.304082 0.30394C-0.101361 0.709193 -0.101361 1.36443 0.304082 1.76968L6.53416 7.99995L0.304082 14.2302C-0.101361 14.6355 -0.101361 15.2907 0.304082 15.696C0.506044 15.8981 0.771594 15.9997 1.03695 15.9997C1.30231 15.9997 1.56767 15.8981 1.76983 15.696L7.99991 9.4657L14.2302 15.696C14.4323 15.8981 14.6977 15.9997 14.963 15.9997C15.2284 15.9997 15.4938 15.8981 15.6959 15.696C16.1014 15.2907 16.1014 14.6355 15.6959 14.2302L9.46584 7.99995Z"
                                fill="#C1FF72"/>
                        </svg>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <div className="link-container">
                        <Link className="nav-link" to="company" spy={true} smooth={true} offset={0} duration={500} data-bs-dismiss="offcanvas">
                            Company
                        </Link>
                        <Link className="nav-link" to="projects" spy={true} smooth={true} offset={0} duration={500} data-bs-dismiss="offcanvas">
                            Projects
                        </Link>
                        <Link className="nav-link" to="team" spy={true} smooth={true} offset={0} duration={500} data-bs-dismiss="offcanvas">
                            Team
                        </Link>
                    </div>
                    <Link to="contact" spy={true} smooth={true} offset={0} duration={500} data-bs-dismiss="offcanvas">
                        <button type="button" className="btn btn-primary w-100">Contact</button>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default HeaderComponent;