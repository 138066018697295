import React, { useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './contact.scss';
import logo from "../assets/images/logo-brand.svg";
import emailjs from 'emailjs-com';
import TypewriterEffectSignature from "../animated-text-signature/typewrittereffectsignature";

function ContactComponent() {
    const form = useRef();
    const [isSubmitted, setIsSubmitted] = useState(sessionStorage.getItem('contact') ? true : false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [emailError, setEmailError] = useState('');
    const [showEmailError, setShowEmailError] = useState(false);
    const [emailFocused, setEmailFocused] = useState(false); // Nuevo estado para manejar el foco


    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return re.test(String(email).toLowerCase());
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (!validateEmail(e.target.value)) {
            setEmailError("Please enter a valid email address.");
        } else {
            setEmailError("");
            setShowEmailError(false);
        }
    };

    const handleEmailFocus = () => {
        setEmailFocused(true);
    };

    const handleEmailBlur = () => {
        setEmailFocused(false);
        if (!validateEmail(email)) {
            setShowEmailError(true);
        } else {
            setEmailError(false);
        }
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    }

    const handleTextareaChange = (e) => {
        setMessage(e.target.value);
    }

    const sendEmail = (e) => {
        e.preventDefault();
        if (!validateEmail(email)) {
            setEmailError("Please enter a valid email address.");
            return; // Evitar enviar el formulario si el email es inválido
        }

        const templateParams = {
            from_name: name,
            message: message,
            from_email: email
        };

        emailjs.send('service_u8lk3ir', 'template_lt4mfjq', templateParams, 'pnAOqimfP_5De3rcC').then(
            (response) => {
                sessionStorage.setItem('contact', 'submitted');
                setIsSubmitted(true);
                form.current.reset();
                setName('');
                setEmail('');
                setMessage('');
                setEmailError('');
            },
            (error) => {
                console.log('FAILED...', error);
            },
        );
    };

    return (
        <>
            <section id="contact" className="contact">
                <div className="kea-section">
                    {/* Contenido omitido para brevedad */}
                    {isSubmitted ? (
                        <div className="contact__form">
                            <h2 className="contact__form-success-title">Thank you for your submission!</h2>
                            <p className="contact__form-success-subtitle">We've received it and will promptly review the information. We'll get back to you soon with the response. </p>
                            <TypewriterEffectSignature text="KeaLab Team" />
                        </div>
                    ) : (
                        <form ref={form} onSubmit={sendEmail}>
                            <div className="contact__form">
                                <div className="contact__input-container">
                                    <label htmlFor="name" className="contact__label">1</label>
                                    <input onChange={handleNameChange} id="name" type="text" placeholder="Your name" className="w-100"/>
                                </div>
                                <div className="contact__input-container">
                                    <label htmlFor="email" className="contact__label">2</label>
                                    <input
                                        id="email"
                                        type="email"
                                        placeholder="Your e-mail"
                                        className="w-100"
                                        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                                        value={email}
                                        onChange={handleEmailChange}
                                        style={{ color: showEmailError && !emailFocused ? 'red' : '' }}
                                        onBlur={handleEmailBlur}
                                        onFocus={handleEmailFocus}
                                    />
                                </div>
                                <div className="contact__input-container">
                                    <label htmlFor="textarea" className="contact__label">3</label>
                                    <textarea id="textarea" placeholder="Type here your message..."
                                              className="w-100" onChange={handleTextareaChange}></textarea>
                                </div>
                            </div>
                            <div className="contact__button-container">
                                <button type="submit" className="btn btn-primary contact__button"
                                        disabled={!name || !email || !message || emailError}>
                                Send
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </section>
        </>
    );
}

export default ContactComponent;
